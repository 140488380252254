.post-content {
  line-height: 1.6;
  font-size: 1.1rem;

  h1, h2, h3, h4, h5, h6 {
    margin: 2rem 0 1rem;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 { font-size: 2.25rem; }
  h2 { font-size: 1.85rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.25rem; }
  h5 { font-size: 1.1rem; }
  h6 { font-size: 1rem; }

  ul, ol {
    padding-left: 2rem;
    margin: 1rem 0;
  }

  li {
    margin: 0.5rem 0;
    
    > ul, > ol {
      margin: 0.5rem 0;
    }
  }

  .task-list {
    list-style: none;
    padding-left: 0;

    .task-list-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      > input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        
        width: 16px;
        height: 16px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        
        &:checked {
          background-color: $accent;
          border-color: $accent;

          &::before {
            content: '\f00c';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 12px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(145, 94, 255, 0.5);
        }
      }
    }
  }

  blockquote {
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    border-left: 4px solid $accent;
    background: rgba(145, 94, 255, 0.1);

    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }

  a {
    color: $accent;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
    margin: 1rem 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    
    th, td {
      padding: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      text-align: left;
    }

    th {
      background: rgba(145, 94, 255, 0.1);
    }

    tr:nth-child(even) {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}
