.highlight {
  color: #e6e6e6;

  .c, .cm, .c1, .cs { color: #6a737d; font-style: italic; }

  .k, .kc, .kd, .kn, .kp, .kr, .kt { color: #ff7b72; }

  .s, .sb, .sc, .sd, .s2, .se, .sh, .si, .sx { color: #a5d6ff; }
  .sr, .s1, .ss { color: #a5d6ff; }

  .m, .mb, .mf, .mh, .mi, .mo { color: #79c0ff; }

  .n, .na, .nb, .nc, .no, .nd, .ni, .ne, .nf, .nl, .nn, .nx, .py { color: #d2a8ff; }

  .bp, .vc, .vg, .vi, .nv { color: #79c0ff; }

  .o, .ow { color: #ff7b72; }

  .p { color: #e6e6e6; }

  .g { color: #e6e6e6; }
  .gd { color: #ffa198; background-color: #490202; }
  .ge { font-style: italic; }
  .gr { color: #ffa198; }
  .gh { color: #79c0ff; font-weight: bold; }
  .gi { color: #56d364; background-color: #0f5323; }
  .go { color: #8b949e; }
  .gp { color: #8b949e; }
  .gs { font-weight: bold; }
  .gu { color: #79c0ff; }
  .gt { color: #ff7b72; }
  .gl { text-decoration: underline; }
}
