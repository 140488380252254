$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

.blog-container {
    display: flex;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
    gap: 2rem;
    font-family: "open-sans", sans-serif;

    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        padding: 0 0.5rem;
    }
}

.blog-sidebar {
    flex: 0 0 200px;
    position: sticky;
    top: 2rem;
    height: fit-content;
    
    h2 {
        margin-bottom: 1rem;
    }

    .tag-link {
        display: block;
        padding: 0.5rem 0;
        color: white;
        text-decoration: none;
        
        &:hover {
            color: var(--tag-color);
        }
    }

    @media (max-width: $breakpoint-tablet) {
        position: relative;
        top: 0;
        flex: none;
        width: 100%;
        margin-bottom: 1rem;
        
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 0.5rem;
        
        &::-webkit-scrollbar {
            height: 4px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: rgba(155, 89, 182, 0.2);
            border-radius: 2px;
        }
        
        h2 {
            display: none;
        }
        
        .tag-link {
            white-space: nowrap;
            padding: 0.5rem 1rem;
            margin-right: 0.5rem;
            background: rgba(155, 89, 182, 0.1);
            border-radius: 20px;
        }
    }
}

.blog-main {
    flex: 1;
}

.post-preview {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: 1px solid #9b59b6;
    border-radius: 5px;
    overflow: hidden;
    background: color-mix(in srgb, $accent, transparent 80%);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    color: #e0e0e0;
    cursor: pointer;
    position: relative;

    .pin-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 1.2rem;
        color: $accent; 
    }

    h2 {
        margin: 0 0 0.2rem;
        
        a {
            color: #e0e0e0;
            text-decoration: none;
            
            &:hover {
                color: #ffffff;
            }
        }
    }

    .post-meta {
        margin: 0.5rem 0; 
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .post-date {
            font-size: 0.9rem;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.9);
            margin: 0 0 0.3rem;
            display: inline-block;
        }
        
        .tag {
            padding: 0.2rem 0.5rem;
            margin: 0.2rem;
            border-radius: 3px;
            font-size: 0.9rem;
            text-decoration: none;
            color: $bg;
            
            &:hover {
                background: #b2ebf2;
            }
        }

        .post-tags {
            margin-top: 0.5rem;
            margin-bottom: 0.3rem;
        }
    }

    @media (max-width: $breakpoint-mobile) {
        padding: 1rem;
        margin-bottom: 1rem;
        
        h2 {
            font-size: 1.2rem;
        }
        
        .post-meta {
            .post-date {
                font-size: 0.8rem;
            }
            
            .tag {
                font-size: 0.8rem;
                padding: 0.15rem 0.4rem;
            }
        }
    }
}

.post-excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
     margin-top: 0;
}

.post-meta {
    margin: 0;
    padding: 0;
    color: #666;
    
    .post-date {
        font-size: 1.2rem;
        font-weight: bold;
        color: #333;
    }
    
    .tag {
        padding: 0.2rem 0.5rem;
        margin: 0.2rem;
        border-radius: 3px;
        font-size: 0.9rem;
        text-decoration: none;
        color: $bg;
        
        &:hover {
            background: #b2ebf2;
        }
    }
}

.post-container {
    display: flex;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
    gap: 2rem;

    .sidebar {
        flex: 0 0 250px;
        position: sticky;
        top: 2rem;
        height: fit-content;
    }

    article.post {
        flex: 1;
        max-width: 700px;
        line-height: 1.6;
        
        h1 {
            margin-top: 0;
        }
        
        img {
            max-width: 100%;
            height: auto;
        }
    }

    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        padding: 0 0.5rem;
        
        .sidebar {
            position: relative;
            top: 0;
            width: 100%;
            margin-bottom: 1rem;
        }
        
        article.post {
            max-width: 100%;
        }
    }
}

.table-of-contents {
    margin-top: 2rem;
    padding: 1rem;
    background: #f8f8f8;
    border-radius: 4px;
    
    #toc {
        font-size: 0.9rem;
        
        a {
            color: #666;
            text-decoration: none;
            
            &:hover {
                color: #000;
            }
        }
    }
}

.back-to-blog {
    display: block;
    margin-bottom: 1rem;
    color: #666;
    text-decoration: none;
    
    &:hover {
        color: #000;
    }
}

.post-content {
    table {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    
    img {
        max-width: 100%;
        height: auto;
    }
    
    pre {
        overflow-x: auto;
        padding: 1rem;
        -webkit-overflow-scrolling: touch;
    }
}