@import 'rouge/github-dark';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.lineno, .gl {
  text-decoration: none !important;
}

pre.lineno {
    text-decoration: none;
}

div.highlight {
  background: #1a1b26;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 1.5rem 0;
  position: relative;
  overflow: hidden; 

  &::before {
    content: attr(data-lang);
    padding-left: 15px;
    display: block;
    height: 24px;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .copy-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    padding: 0 1rem;
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    transition: color 0.2s;
    z-index: 1;

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }

    &.copied {
      color: #4CAF50;
      svg {
        display: none;
      }
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }

  // Code without line numbers
  pre.highlight {
    margin: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    overflow-x: auto;
    font-size: 16px;
  }

  // Table with line numbers
  table {
    border-spacing: 0;
    border: none;
    margin: 0;
    width: 100%;
    background: transparent;

    pre { 
      margin: 0;
      background: transparent;
    }

    td {
      padding: 0;
      border: none;
      background: transparent;
    }

    td.gutter {
      width: 50px;
      text-align: right;
      padding: 2rem 0.75rem 1.25rem;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.02);
      
      pre {
        margin: 0;
        color: rgba(255, 255, 255, 0.4);
        user-select: none;
        text-decoration: none;

        .gl, .lineno {
          text-decoration: none;
        }
      }
    }

    td.code {
      padding: 0;
    }
  }
}

code {
    font-family: 'Roboto Mono', monospace;
}

// Inline code styling
code.highlighter-rouge {
  background: rgba(145, 94, 255, 0.15);
  color: #e6e6e6;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.9em;
  border: 1px solid rgba(145, 94, 255, 0.2);
}
