@import "vars";

.section-header {
  display: flex;
  gap: 15px;
  align-items: center;
  transform: translateX(-22px);
  margin-top: 50px;
  margin-bottom: 25px;

  @media only screen and (max-width: 400px) {
    transform: none;
  }

  @media only screen and (max-width: 360px) {
    grid-template-columns: 50px 1fr;
    margin-bottom: 0;
  }

  h1 {
    font-family: "input-mono", monospace;
    margin: 0;
    font-size: 40px;
    padding-bottom: 5px;
    padding-left: 10px;

    @media only screen and (max-width: 360px) {
      font-size: 30px;
    }
  }

  i {
    font-size: 50px;
    color: $accent;
    justify-self: center;
    align-self: center;
  }
}