@import "vars";

#nav {
  position: sticky;
  top: 0;
  background-color: $bg;
  z-index: 10;
  padding: 20px $default-margin;

  @media only screen and (max-width: 1600px) {
    padding: 20px $margin-1600;
  }

  @media only screen and (max-width: 1000px) {
    padding: 20px $margin-1000;
  }

  display: grid;
  grid-template-rows: 100%;
  justify-content: left;
  grid-template-columns: 45% 45%;

  #links {
    display: flex;
    flex-wrap: nowrap;
    gap: 40px;
    justify-content: right;
    align-items: center;
  }

  .nav-link {
    font-family: "input-mono", monospace;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    width: fit-content;
    height: fit-content;
    color: $p-text;

    text-decoration: none;
    text-align: left;
    transition: $th-transition;
  }

  #nav-logo {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    border-radius: 3px;

    width: 60px;
    height: 60px;
    background-color: $p-text;
    color: $bg;
    margin: 0;

    h1 {
      font-family: "input-mono", monospace;
      font-weight: 700;
      font-style: normal;
      font-size: 40px;
      text-align: center;
      margin: 0;
    }
  }

  .nav-link:hover {
    color: $accent;
  }
}