$bg: #0F172A;
$p-text: #FFF;
$s-text: #F5F5F5;
$accent: #915EFF;

$t-stat: #A0D9EF;
$t-award: #FF7E83;
$t-course: #DA70D6;
$t-tech: #ABF1BC;

$default-margin: 250px;
$margin-1600: 100px;
$margin-1000: 40px;
$margin-400: 25px;
$max-card-width: 600px;

// Text hover transition
$th-transition: 0.3s;